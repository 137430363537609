import { Stack, CircularProgress, CircularProgressProps } from '@mui/material';
import { alpha } from '@mui/system';
import { mergeSx } from '../../utils';
import { WithSx } from '../../constants';

type LoadingProps = WithSx & CircularProgressProps & { loading: boolean };

const LoadingComponent = ({ loading, sx, ...props }: LoadingProps) =>
  loading ? (
    <Stack
      sx={mergeSx(
        {
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          zIndex: ({ zIndex }) => zIndex.modal,
          backgroundColor: ({ palette }) => alpha(palette.grey[800], 0.6),
        },
        sx
      )}
    >
      <CircularProgress size={120} {...props} sx={{ color: 'secondary.main' }} />
    </Stack>
  ) : null;

export const Loading = LoadingComponent;
