import { Grid, Box } from '@mui/material';
import { Body1, ContentCard, Heading3, Heading5 } from '../components';
import { ROUTE } from '../constants';

export const HomePage = () => (
  <Grid container spacing={0} sx={{ background: 'common.white', height: '100%' }}>
    <Grid xs={3} lg={3} item />

    <Grid
      xs={9}
      lg={9}
      item
      sx={{
        background: `url(/images/login-placeholder.png) no-repeat`,
        backgroundSize: 'cover',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          maxWidth: '650px',
          height: 'auto',
          position: 'absolute',
          top: '30%',
          left: '-30%',
          p: 4,
        }}
      >
        <ContentCard
          cardTitle={<Heading3 locale="homePage.headLine" />}
          button1={{
            href: ROUTE.signin,
            locale: 'homePage.buttons.signIn',
            color: 'secondary',
            fullWidth: true,
            sx: { p: 1.5 },
          }}
        >
          <Heading5 locale="homePage.subHeadLine" sx={{ textTransform: 'inherit' }} />
          <Body1 locale="homePage.body" sx={{ mt: 1 }} />
        </ContentCard>
      </Box>
    </Grid>
  </Grid>
);
