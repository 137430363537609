import { FC } from 'react';
import { Copy, CopyProps } from './Copy';

export const Heading1: FC<CopyProps<'h1'>> = props => (
  <Copy component="h1" variant="h1" {...props} />
);

export const Heading2: FC<CopyProps<'h2'>> = props => (
  <Copy component="h2" variant="h2" {...props} />
);

export const Heading3: FC<CopyProps<'h3'>> = props => (
  <Copy component="h3" variant="h3" {...props} />
);
export const Heading4: FC<CopyProps<'h4'>> = props => (
  <Copy component="h4" variant="h4" {...props} />
);
export const Heading5: FC<CopyProps<'h5'>> = props => (
  <Copy component="h5" variant="h5" {...props} />
);
export const Heading6: FC<CopyProps<'h6'>> = props => (
  <Copy component="h6" variant="h6" {...props} />
);
export const Subtitle1: FC<CopyProps<'p'>> = props => (
  <Copy component="p" variant="subtitle1" {...props} />
);

export const Body1: FC<CopyProps<'p'>> = props => <Copy component="p" variant="body1" {...props} />;

export const Body2: FC<CopyProps<'p'>> = props => <Copy component="p" variant="body2" {...props} />;

export const ButtonText: FC<CopyProps<'span'>> = props => (
  <Copy component="span" variant="button" {...props} />
);

export const Caption: FC<CopyProps<'span'>> = props => (
  <Copy component="span" variant="caption" {...props} />
);
