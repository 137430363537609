import { ElementType, HTMLAttributes } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { RichTextComponent, isElement } from '../utils';

export const Heading: RichTextComponent = (node, children) =>
  isElement(node) && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(node.name) ? (
    <Typography
      {...node.attribs}
      component={node.name as ElementType<HTMLAttributes<HTMLElement>>}
      variant={node.name as TypographyProps['variant']}
    >
      {children}
    </Typography>
  ) : null;
