import { BoxProps } from '@mui/system';
import { Box } from '@mui/material';
import { mergeSx } from '../../utils';

export interface ImageProps extends BoxProps {
  src?: string;
  alt?: string;
}

export const Image = ({ sx, src, alt, ...props }: ImageProps) => (
  <Box
    component="img"
    src={src}
    alt={alt || ''}
    sx={mergeSx(
      {
        height: '100%',
        width: '100%',
      },
      sx
    )}
    {...props}
  />
);
