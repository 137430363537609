import { atom } from 'jotai';
import {
  AboutLead,
  ContactInfo,
  LeadDetail,
  LeadVisuals,
  MappedLead,
  MaterialItem,
  Product,
  Project,
  ProjectDetails,
  RawLead,
  RawUserInfo,
  RWLink,
} from '../@types/lead';
import { formatAddressline1 } from '../utils';

// TODO: move types and use proper matching interface in components
export interface SectionTitle {
  name: string;
  text: string;
}
export type Label<T> = Record<keyof T, string>;

export type DetailLabels<T> = {
  [K in keyof T]: Label<T[K]>;
};

// TODO: use locale system
export const DETAIL_LABELS: DetailLabels<Omit<LeadDetail, 'materialList'>> = {
  contactInfo: {
    email: 'Email',
    phone: 'Phone',
    zip: 'Zip',
    leadName: 'Lead Name',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
  },
  aboutLead: {
    predictiveCloseRate: 'Predictive Close Rate',
    leadLabel: 'Lead Label',
    source: 'Source',
    entryPoint: 'Entry Point',
    lastActivity: 'Last Activity',
  },
  projectDetails: {
    timeSpent: 'Time Spent',
    designsMade: 'Designs Made',
    totalProductsApplied: 'Total Products Applied',
    numberOfColorsApplied: 'Number of colors Applied',
    numberOfPageViews: 'Number of Page views',
  },
};
// TODO: use locale system
export const SECTION_TITLES: Array<SectionTitle> = [
  { name: 'contactInfo', text: 'Contact Info' },
  { name: 'aboutLead', text: 'About Lead' },
  { name: 'projectDetails', text: 'Project Details' },
  { name: 'materialList', text: 'Material List' },
  { name: 'initialProjectImage', text: 'Project Design Before' },
  { name: 'finalProjectImage', text: 'Project Design After' },
  { name: 'appliedProduct', text: 'Applied Products' },
];

export interface LeadDetailItem {
  label?: string;
  value: string;
}

// todo: fix this type to be either leadDetail Items or Materials???
export interface LeadSections {
  sectionTitle: SectionTitle['text'];
  leadDetailItems?: Array<LeadDetailItem>;
  materials?: Array<MaterialItem>;
}

export class Lead implements MappedLead {
  leadDetail: LeadDetail;

  leadVisuals: LeadVisuals;

  sectionTitles: Array<SectionTitle>;

  labels: DetailLabels<Omit<LeadDetail, 'materialList'>>;

  projects: Array<Project>;

  constructor(
    rawLead?: RawLead,
    sectionTitles?: Array<SectionTitle>,
    detailLabels?: DetailLabels<LeadDetail>
  ) {
    this.leadDetail = {
      contactInfo: Lead.getMappedContactInfo(rawLead?.userInfo),
      aboutLead: Lead.getMappedAboutLead(rawLead),
      // TODO: move projectDetails to be contained within a current project object instead to support multiple projects per lead?
      projectDetails: Lead.getMappedFirstProjectDetails(rawLead),
      materialList: rawLead?.projects[0]?.materialList ?? [],
    };

    this.leadVisuals = {
      initialProjectImage: rawLead?.projects[0]?.projectInfo?.photoOfHouse ?? '',
      finalProjectImage: rawLead?.projects[0]?.projectInfo?.photoOfLastDesign ?? '',
      appliedProducts: rawLead?.projects[0]?.projectInfo?.productApplied ?? [],
    };

    this.projects = Lead.getProjects(rawLead);

    this.sectionTitles = sectionTitles ?? SECTION_TITLES; // TODO: use Locale system

    this.labels = detailLabels ?? DETAIL_LABELS; // TODO: use Locale system
  }

  private static getMappedContactInfo(rawUserInfo?: RawUserInfo): ContactInfo {
    return {
      leadName: `${rawUserInfo?.firstName ?? ''} ${rawUserInfo?.lastName ?? ''}`, // TODO: new function to concat lead name from firt and last
      phone: rawUserInfo?.phone ?? '',
      email: rawUserInfo?.email ?? '',
      addressLine1: rawUserInfo?.address ? formatAddressline1(rawUserInfo?.address) : '',
      addressLine2: rawUserInfo?.address?.address2 ?? '',
      zip: rawUserInfo?.zip ?? '',
    };
  }

  private static getMappedAboutLead(rawLead?: RawLead): AboutLead {
    return {
      leadLabel: rawLead?.scienceInfo?.leadLabel ?? '',
      predictiveCloseRate: rawLead?.scienceInfo?.predictiveCloseRate ?? '',
      source: (rawLead?.siteName || rawLead?.siteIdentifier) ?? '',
      entryPoint: rawLead?.userInfo?.formSource ?? '',
      lastActivity: rawLead?.digitalInfo?.visitDate ?? null,
    };
  }

  private static getMappedFirstProjectDetails(rawLead?: RawLead): ProjectDetails {
    return {
      timeSpent: rawLead?.digitalInfo?.timeOnSite ?? '',
      designsMade: rawLead?.userInfo?.totalProjects ?? 0,
      totalProductsApplied: rawLead?.digitalInfo?.productApplies ?? 0,
      numberOfColorsApplied: rawLead?.digitalInfo?.colorApplies ?? 0,
      numberOfPageViews: rawLead?.digitalInfo?.pageViews ?? '',
    };
  }

  private static getProjects(rawLead?: RawLead): Array<Project> {
    return rawLead?.projects?.length
      ? rawLead?.projects
      : [
          {
            projectInfo: {
              projectId: 'default',
              projectCreated: null,
              photoOfHouse: 'images/project-before-placeholder.png',
              photoOfLastDesign: 'images/project-after-placeholder.png',
              productApplied: [] as Array<Product>,
            },
            links: [] as Array<RWLink>,
            materialList: [] as Array<MaterialItem>,
          },
        ];
  }

  getSectionTitleByName(propertyName: string): string {
    const title = this.sectionTitles.find(t => t.name === propertyName) ?? '';

    return title ? title.text : 'MISSING TITLE';
  }

  // TODO: fix typing on reduce
  private getSectionDetailList(
    propertyName: keyof LeadDetail | keyof DetailLabels<LeadDetail>
  ): Array<LeadDetailItem> {
    const labels = propertyName !== 'materialList' && this.labels[propertyName];
    const values = this.leadDetail[propertyName];
    const list = Object.entries(values) as Array<[keyof LeadDetail, LeadDetail[keyof LeadDetail]]>;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const result = list.reduce((acc: any, [key, value]: any) => {
      if (key !== 'leadLabel') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc.push({ label: labels[key], value });
      }

      // if (key === 'addressLine1') {
      //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //   // @ts-ignore
      //   acc.push({ label: labels[key], value: formatAddressline1(value) });
      // }

      return acc;
    }, []);

    return result;
  }

  // TODO: fix typing on reduce
  getDetailAsList(): Array<LeadSections> {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const detailList = Object.entries(this.leadDetail).reduce((acc: any, curr: any) => {
      const detail = {
        sectionTitle: this.getSectionTitleByName(curr[0]),
        leadDetailItems: curr[0] !== 'materialList' ? this.getSectionDetailList(curr[0]) : null,
        materials: curr[0] === 'materialList' ? curr[1] : null,
      };
      const result = [...acc, detail];

      return result;
    }, []);

    return detailList;
  }
}

export const leadAtom = atom<Lead>(new Lead());

export const leadIdAtom = atom<string>('');
