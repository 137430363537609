import { Box, Card, CardContent, Grid } from '@mui/material';
import { Product } from '../@types/lead';
import { Body1, Body2 } from './elements/Typography';
import { AppliedProductItem } from './AppliedProductItem';

export interface AppliedProductsCollectionProps {
  appliedProducts: Array<Product>;
}

export const AppliedProductsCollection = ({ appliedProducts }: AppliedProductsCollectionProps) => (
  <Box padding={2} maxHeight="50%" mb={4}>
    <Body1
      fontWeight={600}
      locale="lead.projectInfo.productApplied"
      py={2}
      sx={{ textTransform: 'uppercase' }}
    />
    <Grid container direction="row" spacing={1}>
      {appliedProducts.length >= 1 ? ( // TODO: create utility for array NotEmpty
        appliedProducts.map(product => (
          <Grid
            key={`${product.productName}-${product.productType}`}
            item
            xs={12}
            md={6}
            lg={4}
            xl={3}
            minWidth={{ xs: '300px', md: '`50px' }}
          >
            <AppliedProductItem product={product} />
          </Grid>
        ))
      ) : (
        <Card variant="outlined">
          <CardContent>
            <Body2>No products found</Body2>
          </CardContent>
        </Card>
      )}
    </Grid>
  </Box>
);
