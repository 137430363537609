import { FC } from 'react';
import { IconButton, InputAdornment, Stack, Tooltip } from '@mui/material';
import { Controller } from 'react-hook-form';
import { InfoOutlined } from '@mui/icons-material';
import { Button, Icon, TitleDescription, AnchorLink, Body1, Heading5, Caption } from '../elements';
import { PlainInput } from '../widgets';
import { WithForm } from '../../constants';

export type ForgotPasswordFormFields = Record<'email' | 'site', string>;

export type ForgotPasswordFormProps = WithForm<ForgotPasswordFormFields> & {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  route: string;
};

export const ForgotPasswordFormLayout: FC<ForgotPasswordFormProps> = ({
  onSubmit,
  control,
  formState: { errors, isValid },
  route,
}) => (
  <form onSubmit={onSubmit}>
    <Stack>
      <Stack direction="row" sx={{ pb: 5 }}>
        <IconButton sx={{ pl: 0 }}>
          <AnchorLink path={route}>
            <Icon type="back" color="primary" />
          </AnchorLink>
        </IconButton>
      </Stack>
      <TitleDescription title={<Heading5 locale="forgotPasswordPage.heading" />}>
        <Body1 locale="forgotPasswordPage.body" sx={{ pb: 3 }} />
      </TitleDescription>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <PlainInput
            {...field}
            required
            label={<Body1 locale="forgotPasswordPage.email" />}
            error={{ locale: errors?.email?.message }}
          />
        )}
      />
      <Controller
        name="site"
        control={control}
        render={({ field }) => (
          <PlainInput
            {...field}
            placeholder="https://rwpro.renoworks.com?contractor=yourApp"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={<Caption locale="loginPage.helpers.site" />}>
                    <InfoOutlined fontSize="small" color="secondary" />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            required
            label={<Body1 locale="loginPage.site" />}
            error={{ locale: errors?.site?.message }}
          />
        )}
      />
    </Stack>
    <Button
      fullWidth
      type="submit"
      locale="forgotPasswordPage.buttonText"
      color="secondary"
      variant="contained"
      disabled={!isValid}
      sx={{ p: 1.5 }}
    />
  </form>
);
