import { ReactNode } from 'react';
import { Stack, StackProps } from '@mui/material';

export type TitleDescriptionProps = Omit<StackProps, 'title'> & {
  title?: ReactNode;
};

export const TitleDescription = ({ title, children, ...props }: TitleDescriptionProps) => (
  <Stack spacing={1} {...props}>
    {title}
    {children}
  </Stack>
);
