import { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';
import { Button, Typography, TypographyProps, ButtonProps, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { mergeSx } from '../../utils';
import { WithSx } from '../../constants';

type IsExternalValidator = RegExp | ((path: string) => boolean);

type BaseAnchorLinkProps = WithSx & {
  /**
   * If the component should render as a MUI Button
   */
  button?: boolean;
  /**
   * Additional props to spread over the Button component (if turned on)
   */
  buttonProps?: Omit<ButtonProps, 'href'>;
  /**
   * Children to pass to the component. This is usually the label of the link
   */
  children: React.ReactNode;
  /**
   * URL path
   */
  path: string;
  /**
   * Override the validation checking if the url path is an external path
   */
  isExternalValidator?: IsExternalValidator;
  /**
   * Target value to use. If not provided then will fallback to `self` for internal
   * links and `_blank` for external.
   */
  forceTarget?: string;
  /**
   * If the component should not be clickable.
   *
   * For button types, `readonly` is not supported; use `buttonProps.disabled` instead
   */
  readonly?: boolean;
  /**
   * Additional props to spread over the Typography component.
   * This component renders when props include:
   *
   * ```json
   * {
   *  button: false,
   *  readonly: true,
   * }
   * ```
   */
  typographyProps?: TypographyProps;
  /**
   * If the component should NOT use react router for internal links.
   * Will be ignored for external links
   */
  noReactRouter?: boolean;
};

// Children is required in this component vs optional in the base interface
export type AnchorLinkButtonProps = BaseAnchorLinkProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> & {
    button: true;
  };

// Children is required in this component vs optional in the base interface
type AnchorLinkAnchorProps = BaseAnchorLinkProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'children'> & {
    button?: false;
  };

export type AnchorLinkProps = AnchorLinkAnchorProps | AnchorLinkButtonProps;

type ButtonOnClickType = ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
type AnchorOnClickType = AnchorHTMLAttributes<HTMLAnchorElement>['onClick'];

/**
 * Check if the provided path is an internal or external link
 *
 * @param path URL path to validate
 * @param validator Optional validation function or regex to use instead of default
 *
 * @example
 * isExternal('/pages/home'); // false
 * isExternal('http://localhost:3000/pages/home'); // false - if on `http://localhost:3000`
 * isExternal('http://google.ca'); // true
 */
function isExternal(path: string, validator?: IsExternalValidator) {
  if (!validator) {
    const a = document.createElement('a');
    a.href = path;
    return /^https?:\/\//.test(path) || window.location.host !== a.host;
  }
  if (validator instanceof RegExp) {
    return validator.test(path);
  }
  return validator(path);
}

/**
 * Render a link. This will determine if the link is internal or external and render
 * a link that uses React router for internal links and a standard anchor element
 * for external links.
 *
 * Additional props provided will be passed to the rendered element except when the component
 * is `readonly`. Since the primary use of this component is to either render a button or
 * anchor element. The `readonly` text element should only be a temporary state if used.
 * If it's intended to be the permanent state, then it would be better for the implementation
 * to use the `Typography` component directly instead of the `AnchorLink` component.
 */
export function AnchorLink({
  path,
  button,
  className,
  buttonProps,
  children,
  isExternalValidator,
  forceTarget,
  readonly,
  noReactRouter,
  onClick,
  sx,
  ...htmlProps
}: AnchorLinkProps) {
  const isInternalPath = !isExternal(path, isExternalValidator);
  const target = forceTarget || isInternalPath ? undefined : '_blank';

  if (button) {
    // Type clash on `color` prop. Removing from here. If need to implement
    // then `buttonProps.color` should be used instead
    const { color, ...buttonHtmlProps } = htmlProps;

    return isInternalPath && !noReactRouter ? (
      // Internal
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877
      <Button
        className={className}
        color="primary"
        {...buttonProps}
        component={Link}
        sx={mergeSx(sx, buttonProps?.sx)}
        to={path}
        target={target}
        onClick={buttonProps?.onClick ?? (onClick as ButtonOnClickType)}
        {...buttonHtmlProps}
      >
        {children}
      </Button>
    ) : (
      // External
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877
      <Button
        className={className}
        {...buttonProps}
        sx={mergeSx(sx, buttonProps?.sx)}
        href={path}
        target={target}
        rel="noopener noreferrer"
        onClick={buttonProps?.onClick ?? (onClick as ButtonOnClickType)}
        {...buttonHtmlProps}
      >
        {children}
      </Button>
    );
  }

  if (readonly) {
    return (
      <Typography sx={sx} className={className}>
        {children}
      </Typography>
    );
  }

  if (isInternalPath && !noReactRouter) {
    return (
      <MuiLink
        component={Link}
        className={className}
        sx={sx}
        to={path}
        target={target}
        onClick={onClick as AnchorOnClickType}
        {...(htmlProps as AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {children}
      </MuiLink>
    );
  }

  return (
    <MuiLink
      href={path}
      className={className}
      target={target}
      onClick={onClick as AnchorOnClickType}
      rel="noopener noreferrer"
      {...(htmlProps as AnchorHTMLAttributes<HTMLAnchorElement>)}
    >
      {children}
    </MuiLink>
  );
}
