// eslint-disable-next-line prettier/prettier
import type { Theme } from '@mui/material';
import type { SxProps } from '@mui/system';
import * as yup from 'yup';
import { SxArray } from './constants';
import { Address } from './@types/lead';

export const mergeSx = (...sxs: (SxProps<Theme> | false | undefined)[]) =>
  sxs.reduce<SxArray>((arr, sx) => (!sx ? arr : [...arr, ...(Array.isArray(sx) ? sx : [sx])]), []);

export const createFormSchema = <T extends object>(schema: Record<keyof T, yup.BaseSchema>) =>
  yup.object().shape(schema);

// Temp function until APIs are implemented
export const pause = (ms: number) =>
  new Promise(resolve => {
    setTimeout(resolve, ms);
  });

export const toSentance = (camelStr = '', capitalize?: boolean) => {
  const str = camelStr.replace(/([A-Z])/g, ' $1').toLowerCase();
  return capitalize ? `${str.charAt(0).toUpperCase()}${str.slice(1)}` : str;
};

/**
 * Checks if the item exists. This is useful to replace `[].filter(v => !!v)` as the result of
 * this array will be typed without the potential `null` or `undefined` value, which can cause
 * headaches needing to account for them later in either the chain or implementing code.
 *
 * See: https://stackoverflow.com/a/46700791
 *
 * @param value Value to type check
 * @returns If value exists and correct type predicate
 */
export function exists<TValue>(value: TValue | null | undefined | boolean): value is TValue {
  return value !== null && value !== undefined && value !== false;
}

export function existsWithoutBooleanCheck<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const stringOrDefault = (str: string, defaultStr  = '(Unknown)') => str || defaultStr;

export const formatAddressline1 = (address: Address) => {
  if (!address.address && !address.city && !address.state) return '-';
  
  return `${address.address ? `${address.address}, ` : ''}${address.city ? `${address.city}, `: ''}${address.state ?? ''}`
}

export const getContractorSiteParam = (siteUrl: string) => {
  if (!siteUrl) return '';

  const url = new URL(siteUrl);
  
  return url.searchParams.get('contractor') ?? '';
};
