import { useState, forwardRef } from 'react';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { CopyProps, Caption } from '../elements';
import { mergeSx, toSentance } from '../../utils';

export type PlainInputProps = Omit<TextFieldProps, 'helperText' | 'error'> & {
  error?: CopyProps;
};

type InputRefElement = HTMLInputElement | HTMLDivElement;

export const PlainInput = forwardRef<InputRefElement, PlainInputProps>(
  // eslint-disable-next-line react/prop-types
  ({ error, sx, name, ...props }, ref) => (
    <TextField
      fullWidth
      variant="standard"
      name={name}
      {...props}
      ref={ref}
      sx={mergeSx(
        {
          color: 'common.black',
          fontSize: '16px',
          '& .MuiTypography-root': {
            display: 'inline-block',
          },
        },
        sx
      )}
      // Can't use Body1 as it forces <p></p>
      helperText={
        <Caption
          variant="body1"
          {...error}
          sx={{ color: 'error.main' }}
          data={{ fieldName: toSentance(name, true) }}
        />
      }
    />
  )
);

export type PasswordInputProps = PlainInputProps & { onIconClick?(newState: boolean): void };

export const PasswordInput = forwardRef<InputRefElement, PasswordInputProps>(
  // eslint-disable-next-line react/prop-types
  ({ onIconClick, ...props }, ref) => {
    const [show, setShow] = useState(false);

    const onButtonClick = () => {
      setShow(v => {
        onIconClick?.(!v);
        return !v;
      });
    };

    return (
      <PlainInput
        {...props}
        ref={ref}
        type={show ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={onButtonClick}>
                {show ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);
