import { BottomNavigation, Box, Stack } from '@mui/material';
import { SplitPane } from './SplitPane';
import { AnchorLink, Caption, Image } from '../elements';

export const PageFooterLayout = () => (
  <BottomNavigation sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
    <SplitPane
      spacing={2}
      columnGridRatios={[
        { xs: 6, sm: 4, md: 3 },
        { xs: 6, sm: 8, md: 9 },
      ]}
      sx={{
        root: {
          p: 2,
          height: 'auto',
          alignItems: { xs: 'inherit', sm: 'center' },
          justifyContent: 'space-between',
          backgroundColor: 'primary.main',
        },
        col2: { display: 'flex', justifyContent: 'end' },
      }}
      column1={
        <Stack direction="row" spacing={1} alignItems="baseline">
          {/*  TODO: correct links to docs and external route. */}
          <AnchorLink path="https://www.renoworks.com/privacy-policy">
            <Caption
              color="common.white"
              locale="footer.privacyPolicy"
              sx={{ verticalAlign: 'top' }}
            />
          </AnchorLink>
          <Caption color="common.white" fontSize="1.25em" sx={{ verticalAlign: 'bottom' }}>
            |
          </Caption>
          <AnchorLink path="https://www.renoworks.com/tos">
            <Caption color="common.white" locale="footer.tos" sx={{ verticalAlign: 'top' }} />
          </AnchorLink>
        </Stack>
      }
      column2={
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          justifyContent="end"
          alignItems={{ xs: 'start', sm: 'center' }}
          sx={{
            width: '100%',
            color: 'common.white',
            whiteSpace: 'nowrap',
            mt: { xs: 1 },
            display: { xs: 'none', sm: 'inherit' },
          }}
        >
          <Box sx={{ justifySelf: 'end' }}>
            <Image
              src="/images/poweredby-renoworks-white.png"
              alt="Powered by renoworks"
              sx={{
                maxWidth: '100px',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
          </Box>
        </Stack>
      }
    />
  </BottomNavigation>
);
