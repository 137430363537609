import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { Button, TitleDescription, Body1, ButtonProps, Heading5 } from '../elements';

export type ResetPasswordSuccessLayoutProps = {
  buttonProps: Omit<ButtonProps, 'locale'>;
};

export const ResetPasswordSuccessLayout: FC<ResetPasswordSuccessLayoutProps> = ({
  buttonProps,
}) => (
  <Stack sx={{ width: '60%' }}>
    <TitleDescription title={<Heading5 locale="resetPasswordPage.successHeading" />}>
      <Body1 locale="resetPasswordPage.successBody" />
    </TitleDescription>
    <Box sx={{ mt: 5 }}>
      <Button
        {...buttonProps}
        locale="resetPasswordPage.successLink"
        color="secondary"
        fullWidth
        sx={{ p: 1.5 }}
      />
    </Box>
  </Stack>
);
