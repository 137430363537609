import * as yup from 'yup';
import { useAsyncCallback } from 'react-async-hook';
import { useNavigate } from 'react-router';
import { useAtom } from 'jotai';
import { Image } from '../components/elements/Image';
import { apiUserToAtomUser, loginUser } from '../services';
import { useSubmit, useForm, useApiError, ApiError } from '../hooks';
import { useToastAtom, useUserAtom } from '../atoms';
import { SplitPane, LoginFormLayout, LoginFormFields } from '../components';
import { createFormSchema, getContractorSiteParam } from '../utils';
import { ROUTE } from '../constants';
import { leadIdAtom } from '../atoms/leadAtom';

const defaultValues: LoginFormFields = {
  email: '',
  password: '',
  site: '',
};

const formSchema = createFormSchema<LoginFormFields>({
  email: yup
    .string()
    .email('loginPage.errors.invalidEmail')
    .required('loginPage.errors.requiredEmail'),
  password: yup.string().required('loginPage.errors.requiredPassword'),
  site: yup.string().required('loginPage.errors.requiredSite'),
});

export const LoginPage = () => {
  const { login } = useUserAtom();
  const [leadId] = useAtom(leadIdAtom);
  const [apiError, setApiError] = useApiError();
  const formProps = useForm(defaultValues, formSchema);
  const createSubmit = useSubmit(formProps.handleSubmit);
  const navigate = useNavigate();
  const { setToast } = useToastAtom();

  const { execute } = useAsyncCallback(loginUser);

  const onSubmit = createSubmit(async ({ email, password, site }) => {
    try {
      const res = await execute(email, password, getContractorSiteParam(site));
      const userSession = apiUserToAtomUser(res);
      setApiError('reset');
      login(userSession);
      navigate(leadId ? `${ROUTE.leads}/${leadId}` : ROUTE.noLead);
    } catch (error) {
      let message;
      if (error instanceof Error) {
        message = error.message;
        setToast({
          open: true,
          message,
          severity: 'error',
        });
      }
      // eslint-disable-next-line no-console
      console.error('Failed to login user!');
      setApiError(error as ApiError);
    }
  });

  return (
    <SplitPane
      columnGridRatios={[
        { xs: 12, md: 5 },
        { xs: 12, md: 7 },
      ]}
      column1={
        <Image
          src="/images/login-placeholder.png"
          alt="login placeholder"
          sx={{ objectFit: 'cover' }}
        />
      }
      column2={
        <LoginFormLayout
          onSubmit={onSubmit}
          forgetPasswordRoute={ROUTE.forgotPassword}
          formError={apiError}
          {...formProps}
        />
      }
      sx={{
        root: { backgroundColor: 'common.white' },
        col2: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    />
  );
};
