import { ListItem } from '@mui/material';
import { MaterialItem } from '../@types/lead';
import { Body2 } from './elements/Typography';
import { stringOrDefault } from '../utils';

export interface MaterialListItemProps {
  material: MaterialItem;
}

export const MaterialListItem = ({ material }: MaterialListItemProps) => {
  const formattedMaterial = `${stringOrDefault(material.productType)} - ${stringOrDefault(
    material.manufacturer
  )} - ${stringOrDefault(material.color)} `;

  return (
    <ListItem
      sx={{ display: 'list-item', justifyContent: 'start', alignItems: 'center', pb: 0.5, pt: 0.5 }}
    >
      <Body2 sx={{ display: 'inline' }}>{formattedMaterial}</Body2>
    </ListItem>
  );
};
