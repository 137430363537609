import { Route, Routes } from 'react-router-dom';
import { CssBaseline, Stack, Box } from '@mui/material';
import { Suspense } from 'react';
import { Body1, PrimaryNav, Loading, Toast } from '../components';
import { ROUTE } from '../constants';
import { NotFoundPage } from './NotFound';
import { HomePage } from './Home';
import { LoginPage } from './LoginPage';
import { ForgotPasswordPage } from './ForgotPasswordPage';
import { ResetPasswordPage } from './ResetPasswordPage';
import { useLoadingAtom } from '../atoms';
import { useToastAtom } from '../atoms/toastAtom';
import { LeadPage } from './LeadPage';
import { NoLead } from '../components/NoLead';
import { AuthGuard } from '../components/AuthGuard';

export function AppRoutes() {
  const { isLoading } = useLoadingAtom();
  const { toast } = useToastAtom();

  return (
    <>
      <CssBaseline />
      <Suspense fallback={<Loading loading sx={{ backgroundColor: 'common.white' }} />}>
        <Loading loading={isLoading} />
        {/* Actual page layout */}
        <Stack height="100vh">
          <PrimaryNav />
          <Box flex={1} height="90%">
            <Routes>
              <Route path={ROUTE.home} element={<HomePage />} />
              <Route path={ROUTE.signin} element={<LoginPage />} />
              <Route path={ROUTE.forgotPassword} element={<ForgotPasswordPage />} />
              <Route path={ROUTE.resetPassword} element={<ResetPasswordPage />} />
              <Route path={ROUTE.noLead} element={<NoLead />} />
              <Route
                path={`${ROUTE.leads}/:leadId`}
                element={<AuthGuard component={<LeadPage />} />}
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Box>
          <Toast open={toast.open}>
            <Body1 locale={toast.message} />
          </Toast>
        </Stack>
      </Suspense>
    </>
  );
}
