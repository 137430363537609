// eslint-disable-next-line prettier/prettier
import type { Theme } from '@mui/material';
import type { SxProps } from '@mui/system';
import { FormEventHandler } from 'react';
import { UseFormReturn } from 'react-hook-form';

export const fallbackImagePath = '/images/ImageError.jpg'

export const ROUTE = {
  home: '/',
  createAccount: '/create-account',
  signin: '/sign-in',
  account: '/account',
  upgrade: '/upgrade',
  forgotPassword: '/forgot-password',
  billing: '/account/billing',
  subscription: '/account/subscription',
  myProfile: '/account/profile',
  resetPassword: '/reset-password',
  leads: '/leads',
  noLead:'/nolead',
  project: '/project',
  projectProfile: '/project/:id/profile',
  projectInformation: '/project/:id/information',
  projectTracking: '/project/:id/tracking',
  projectMeasurement: '/project/:id/measurement',
  projectFinal: '/project/:id/final',
  verify: '/user/verify',
};

export const rwproPasswordResetRedirectURL = '/pages/account/reset?resetKey={{reset_key}}&lang=en'
// TODO: custom reset flow with new query param if redirect to rwpro site is insufficient
export const redirectUrl = `${ROUTE.resetPassword}?resetKey={{reset_key}}`

export enum SUBSCRIPTION {
  BASIC,
  PRO,
}

export type WithSx = { sx?: SxProps<Theme>; className?: string };
export type WithSxKeys<C extends string> = {
  sx?: Partial<Record<C, SxProps<Theme>>>;
  classes?: Partial<Record<C, string>>;
};
export type SxArray = Extract<SxProps<Theme>, Array<SxProps<Theme>>>;

export type WithForm<T extends object> = UseFormReturn<T> & {
  onSubmit: FormEventHandler<HTMLFormElement>;
  formError?: string;
};

//  this doesn't have contact info ie email or phone # Needs to change to match new Schema
export type Project = {
  leadId: string,
  lead_name: string,
  lead_score: number,
  project_id: string,
  user:{email:string, name:string, data:string}
  address: {
    street: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    latitude: number,
    longitude: number,
  },
  master: string,
  sample: string,
  job: {
    scope: string,
    budget: string,
    time_from: string,
    time_to: string,
    completed: string,
    contactHomeowner?: string,
    defineDates?: string,
    closeSale?: string,
    selectFinalDesign?: string,
    createTakeoffList?: string,
    selectFinalProducts?: string,
    purchase?: string,
    startJob?: string,
  },
  measurements_order: object,
}

// TODO: This will reflect the response the API gives us when attaching a project
export type AttachProjectResponse = {
  success: boolean;
}
