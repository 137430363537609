import UserApiFactory from '@renoworks/user-api';
import axios, { AxiosResponse } from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { Lead } from '../atoms/leadAtom';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}/v1/rwleadportal`,
  withCredentials: true,
  headers: {
    'content-type': 'application/json',
  },
});
const mock = new MockAdapter(axios);

export const userApi = (site: string) =>
  UserApiFactory(
    process.env.REACT_APP_BASE_API_URL,
    site,
    // @ts-expect-error this is the correct type not sure why the compiler is complaining
    axiosInstance
  );

mock.onGet('/measurements').reply(200, [
  {
    id: '1',
    title: 'Perimeter/Drip Edge:',
    desc: '492.40 (ft)',
    color: 'green',
  },
  {
    id: '2',
    title: 'Perimeter/Drip Edge:',
    desc: '492.40 (ft)',
    color: 'red',
  },
]);

export const leadApi = axiosInstance;

// TODO: abstract to service and/or hook
export const fetchLeadData = async (
  leadId: string,
  setAtom: (update: Lead | ((prev: Lead) => Lead)) => void
) => {
  try {
    const res: AxiosResponse = await leadApi.get(`/${leadId}`);
    setAtom(new Lead(res.data));
  } catch (error) {
    // TODO: handle error with snackbar or something.
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const projectApi = axiosInstance;

mock
  .onGet('/subscription')
  .reply(200, [{ id: '1', date: 'jan 09 2020', licenseType: 'individual', amount: '$4000' }]);

mock.onGet('/upgrade').reply(200, [
  {
    id: '1',
    heading: 'Basic Subscription',
    content:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et <br/><br/> • Lorem ipsum dolor sit amet <br/>• Consetetur sadipscing elitr sed diam <br/>• Nonumy eirmod tempor',
  },
  {
    id: '2',
    heading: 'Pro Subscription',
    content:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et <br/><br/>• Lorem ipsum dolor sit amet <br/>• Consetetur sadipscing elitr sed diam <br/>• Nonumy eirmod tempor <br/>• Invidunt ut labore et dolore magna <br/>• Lorem ipsum dolor sit amet <br/>• Consetetur sadipscing elitr sed diam',
  },
]);
