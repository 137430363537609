import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type ApiError = {
  code: string;
  message: string;
};

type Props = {
  hideErrorCode?: boolean;
};

const localeKey = 'apiErrorCode';

export const useApiError: () => [string, Function] = ({ hideErrorCode }: Props = {}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const transformApiError = useCallback(
    ({ code, message }: ApiError, data?: object) => {
      const messageOverride = t(`${localeKey}.${code}`, data);
      const title = t(`${localeKey}.codeTitle`);
      return [
        messageOverride.startsWith(localeKey) ? message : messageOverride,
        !hideErrorCode ? `(${title}: ${code})` : '',
      ]
        .join(' ')
        .trim();
    },
    [hideErrorCode, t]
  );

  return [
    errorMessage,
    (error: ApiError | 'reset', data?: object) =>
      setErrorMessage(error === 'reset' ? '' : transformApiError(error, data)),
  ];
};
