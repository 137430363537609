import { ReactNode, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAtom } from 'jotai';
import { useUserAtom } from '../atoms/userAtom';
import { ROUTE } from '../constants';
import { leadIdAtom } from '../atoms/leadAtom';
import { apiUserToAtomUser, getSession } from '../services/user';

export interface AuthGuardProps {
  component?: ReactNode;
}
export const AuthGuard = ({ component }: AuthGuardProps) => {
  const { setUser } = useUserAtom();
  const navigate = useNavigate();
  const { leadId } = useParams();
  const [id, setLeadId] = useAtom(leadIdAtom);

  useEffect(() => {
    if (leadId && !id) {
      setLeadId(leadId);
    }
  }, [leadId, setLeadId, id]);

  useEffect(() => {
    getSession().then(res => {
      if (!res?.user_id) {
        navigate(ROUTE.signin);
      } else {
        const userSession = apiUserToAtomUser(res);
        setUser(userSession);
      }
    });
  }, [navigate, setUser]);

  return <>{component}</>;
};
