import { Stack } from '@mui/material';
import { useCallback, ComponentProps, useMemo } from 'react';
import zxcvbn from 'zxcvbn';
import { mergeSx } from '../../utils';
import { WithSx } from '../../constants';
import { Body1, Icon, StrengthBar } from '../elements';

export type Rule = ComponentProps<typeof Body1> & {
  check(value: string): boolean;
};

export const passwordRules: Rule[] = [
  {
    check: (value: string) => value.length > 5 && value.length < 63,
    locale: 'passwordRules.length',
  },
  {
    check: (value: string) => /[A-Z]/.test(value) && /[a-z]/.test(value),
    locale: 'passwordRules.upperAndLower',
  },
  {
    check: (value: string) => /[!@$^&*#\d]/.test(value),
    locale: 'passwordRules.specialChar',
  },
];

export type PasswordCheckerUiProps = WithSx & {
  rules: Rule[];
  value: string;
  show: boolean;
};

export const PasswordCheckUi = ({ value, rules, show, sx }: PasswordCheckerUiProps) => {
  const strength = useMemo(() => {
    const { score } = zxcvbn(value);
    return score;
  }, [value]);

  const renderIcon = useCallback(
    (valid: boolean) => (
      <Icon
        type={valid ? 'checkmark' : 'close'}
        sx={{
          color: valid ? 'success.main' : 'error.main',
        }}
      />
    ),
    []
  );

  return (
    <>
      {show && (
        <Stack
          spacing={2}
          sx={mergeSx(
            {
              border: '2px solid black',
              p: 2,
              position: 'absolute',
              bottom: '20%',
              backgroundColor: 'common.white',
              zIndex: 100,
            },
            sx
          )}
        >
          <StrengthBar strength={strength} />
          {rules.map(({ check, ...copyProps }) => (
            <Stack direction="row" spacing={1} key={copyProps.locale}>
              <span>{renderIcon(check(value))}</span>
              <Body1 {...copyProps} />
            </Stack>
          ))}
        </Stack>
      )}
    </>
  );
};
