import { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { Paragraph } from './Paragraph';
import { Heading } from './Heading';
import { Anchor } from './Anchor';
import { Ignored } from './Ignored';
import { RichTextComponent, isElement } from '../utils';

const mappers: RichTextComponent[] = [Ignored, Heading, Paragraph, Anchor];

export const replace: HTMLReactParserOptions['replace'] = node =>
  mappers.reduce<ReturnType<RichTextComponent>>(
    (out, mapper) =>
      out ??
      ((isElement(node) &&
        node.type === 'tag' &&
        mapper(node, domToReact(node.children, { replace }))) ||
        null),
    null as ReturnType<RichTextComponent>
  );
