import { Stack } from '@mui/material';
import { LeadDetailCollection } from '../elements/LeadDetailCollection';
import { LeadSections } from '../../atoms/leadAtom';

export interface LeadDetailPaneProps {
  leadSections: Array<LeadSections>;
}

export const LeadDetailPane = ({ leadSections }: LeadDetailPaneProps) => (
  <Stack px={2} width="100%">
    {leadSections.map((ls: LeadSections) => (
      <LeadDetailCollection
        key={ls.sectionTitle}
        sectionDetails={ls.leadDetailItems}
        materials={ls.materials}
        sectionTitle={ls.sectionTitle}
      />
    ))}
  </Stack>
);
