import { memo, ReactNode } from 'react';
import { Box, Alert, AlertColor, Snackbar } from '@mui/material';
import { useToastAtom } from '../../atoms/toastAtom';

export type ToastProps = {
  open: boolean;
  severity?: AlertColor;
  children: ReactNode;
};

export const Toast = memo(({ open, severity, children }: ToastProps) => {
  const { toast, setToast } = useToastAtom();

  const handleClose = () => {
    setToast({ open: false });
  };

  return (
    <Box width="100%">
      <Snackbar
        anchorOrigin={{
          vertical: toast.anchorOrigin?.vertical ?? 'top',
          horizontal: toast.anchorOrigin?.horizontal ?? 'center',
        }}
        autoHideDuration={5000}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={toast.severity || severity}
          elevation={6}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {children}
        </Alert>
      </Snackbar>
    </Box>
  );
});
