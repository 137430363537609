import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { AppRoutes } from './pages';
import { ThemeProvider } from './hooks';
import './locale'; // Init locale

export function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <CssBaseline />
        <AppRoutes />
      </BrowserRouter>
    </ThemeProvider>
  );
}
