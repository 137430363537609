import { Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { leadAtom } from '../../atoms/leadAtom';
import { ProjectImages } from '../ProjectImages';
import { AppliedProductsCollection } from '../AppliedProductsCollection';

export const LeadImagePane = () => {
  // TODO: get Current/active Project from store (and add to store....)
  // temp
  const [lead] = useAtom(leadAtom);

  return (
    <Stack direction="column" px={4} py={2} height="80%" width="100%">
      <ProjectImages
        initialImagePath={lead?.leadVisuals?.initialProjectImage}
        finalImagePath={lead?.leadVisuals?.finalProjectImage}
      />
      <AppliedProductsCollection appliedProducts={lead?.leadVisuals?.appliedProducts ?? []} />
    </Stack>
  );
};
