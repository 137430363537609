import { List, Stack, styled } from '@mui/material';
import { Body1 } from './Typography';
import { LeadDetailItem } from '../../atoms/leadAtom';
import { DetailItem } from './DetailItem';
import { MaterialListItem } from '../MaterialListItem';
import { MaterialItem } from '../../@types/lead';

const StyledStack = styled(Stack)(({ theme }) => ({
  margin: `${theme.spacing(2)} ${theme.spacing(4)}`,
  minWidth: '300px',
}));

export interface LeadDetailCollectionProps {
  sectionTitle: string;
  sectionDetails?: Array<LeadDetailItem>;
  materials?: Array<MaterialItem>;
}

export const LeadDetailCollection = ({
  sectionTitle,
  sectionDetails,
  materials,
}: LeadDetailCollectionProps) => (
  <StyledStack>
    <Body1 fontWeight={600} pb={2} locale={sectionTitle} sx={{ textTransform: 'uppercase' }} />
    {/* TODO: make this less fragile not on section title */}
    {sectionDetails &&
      sectionDetails?.map(d => (
        <DetailItem
          key={d.label}
          leadDetailItem={{
            label: d.label,
            value: d?.value || '-',
          }}
        />
      ))}
    <List sx={{ listStyleType: 'disc', ml: 2, pt: 0 }}>
      {materials && materials?.map(m => <MaterialListItem material={m} />)}
    </List>
  </StyledStack>
);
