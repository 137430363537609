import { useForm as useHookForm, DefaultValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Using function in order to name easier: react/rule-of-hooks
export function useForm<T extends object>(
  defaultValues: DefaultValues<T>,
  schema: yup.ObjectSchema<Record<keyof T, yup.BaseSchema>>
) {
  return useHookForm<T>({ defaultValues, mode: 'onChange', resolver: yupResolver(schema) });
}
