/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable unicorn/prefer-add-event-listener */
/* eslint-disable no-param-reassign */
import { Stack } from '@mui/material';
import { SyntheticEvent } from 'react';
import { Body1 } from './elements/Typography';
import { Image } from './elements/Image';
import { fallbackImagePath } from '../constants';

export interface ProjectImagesProps {
  initialImagePath: string;
  finalImagePath: string;
}

const handleImageError = ({ currentTarget }: SyntheticEvent<HTMLImageElement, Event>) => {
  currentTarget.onerror = null;
  currentTarget.src = fallbackImagePath;
};

export const ProjectImages = ({ initialImagePath, finalImagePath }: ProjectImagesProps) => (
  <Stack maxHeight="50%" spacing={2} px={2}>
    <Body1
      fontWeight={600}
      locale="lead.projectInfo.photoOfHouse"
      pb={1}
      sx={{ textTransform: 'uppercase' }}
    />
    <Image
      id="initialImage"
      src={initialImagePath}
      alt="Before"
      sx={{ objectFit: 'cover' }}
      maxHeight="300px"
      minWidth="300px"
      onError={handleImageError}
    />
    <Body1
      fontWeight={600}
      locale="lead.projectInfo.photoOfLastDesign"
      pb={1}
      sx={{ textTransform: 'uppercase' }}
    />
    <Image
      id="finalImage"
      key={finalImagePath}
      src={finalImagePath}
      alt="After"
      maxHeight="300px"
      minWidth="300px"
      sx={{ objectFit: 'cover' }}
      onError={handleImageError}
    />
  </Stack>
);
