import { FC } from 'react';
import { Box, InputAdornment, Stack, Tooltip } from '@mui/material';
import { Controller } from 'react-hook-form';
import { InfoOutlined } from '@mui/icons-material';
import { AnchorLink, Button, Heading4, Body1, Caption } from '../elements';
import { PlainInput, PasswordInput } from '../widgets';
import { WithForm } from '../../constants';

export type LoginFormFields = Record<'email' | 'password' | 'site', string>;

export type LoginFormLayoutProps = WithForm<LoginFormFields> & {
  forgetPasswordRoute: string;
};

export const LoginFormLayout: FC<LoginFormLayoutProps> = ({
  onSubmit,
  control,
  formState: { errors, isValid },
  forgetPasswordRoute,
  formError,
}) => (
  <Stack
    sx={{
      width: '60%',
      height: '100%',
      justifyItems: 'center',
      justifyContent: 'center',
    }}
  >
    <form onSubmit={onSubmit}>
      <Heading4 locale="loginPage.heading" sx={{ my: 2, textTransform: 'capitalize' }} />
      <Stack sx={{ width: 'inherit' }}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <PlainInput
              {...field}
              required
              label={<Body1 locale="loginPage.email" />}
              error={{ locale: errors?.email?.message }}
            />
          )}
        />
        <Controller
          name="site"
          control={control}
          render={({ field }) => (
            <PlainInput
              {...field}
              placeholder="https://rwpro.renoworks.com?contractor=yourApp"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={<Caption locale="loginPage.helpers.site" />}>
                      <InfoOutlined fontSize="small" color="secondary" />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              required
              label={<Body1 locale="loginPage.site" />}
              error={{ locale: errors?.site?.message }}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <PasswordInput
              {...field}
              required
              label={<Body1 locale="loginPage.password" />}
              error={{ locale: errors?.password?.message }}
            />
          )}
        />
      </Stack>

      {formError && (
        <Stack>
          <Body1 locale={formError} color="error" />
        </Stack>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 'inherit' }}>
        <AnchorLink
          button
          path={forgetPasswordRoute}
          buttonProps={{
            variant: 'text',
            color: 'secondary',
            sx: { textDecoration: 'underline', pl: 0, pb: 0 },
          }}
        >
          <Body1 locale="loginPage.forgotPasswordLink" />
        </AnchorLink>
        <Button
          type="submit"
          locale="loginPage.signIn"
          color="secondary"
          sx={{ width: '50%', p: 1.5 }}
          disabled={!isValid}
        />
      </Box>
    </form>
  </Stack>
);
