import { useState } from 'react';
import * as yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import {
  SplitPane,
  ResetPasswordFormLayout,
  ResetPasswordSuccessLayout,
  ResetFormFields,
  passwordRules,
} from '../components';
import { createFormSchema } from '../utils';
import { useSubmit, useForm } from '../hooks';
import { ROUTE } from '../constants';
import { getPasswordResetKey, resetPassword } from '../services';
import { useToastAtom, useUserAtom } from '../atoms';

const formSchema = createFormSchema<ResetFormFields>({
  email: yup.string().email('formError.required'),
  password: yup
    .string()
    .required('formError.required')
    .min(6, 'formError.passwordLength')
    .max(64, 'formError.passwordMaxLength')
    .matches(/[A-Z]/, 'formError.passwordUpper')
    .matches(/[a-z]/, 'formError.passwordLower')
    .matches(/[!@$^&*#\d]/, 'formError.passwordSpecial'),
  confirmPassword: yup
    .string()
    .required('formError.required')
    .oneOf([yup.ref('password')], 'formError.passwordsMustMatch'),
  site: yup.string().required('formError.required'),
});

export const ResetPasswordPage = () => {
  const { user } = useUserAtom();
  const defaultValues: ResetFormFields = {
    password: '',
    confirmPassword: '',
    email: user.email || '',
    site: '',
  };

  const [searchParams] = useSearchParams();
  const [success, setSuccess] = useState(false);
  const formProps = useForm(defaultValues, formSchema);
  const createSubmit = useSubmit(formProps.handleSubmit);
  const navigate = useNavigate();
  const { setToast } = useToastAtom();

  const onSubmit = createSubmit(async data => {
    try {
      let key = searchParams.get('reset-key');
      if (!key) {
        key = await getPasswordResetKey(user.email || data.email, 'marketplace');
      }
      await resetPassword(user.email || data.email, data.password, key, data.site);
      setSuccess(true);
    } catch (error) {
      setToast({
        open: true,
        severity: 'error',
        message: 'resetPasswordPage.errors.resetPasswordError',
      });
    }
  });

  return (
    <SplitPane
      columnGridRatios={[
        { xs: 12, lg: 5 },
        { xs: 12, lg: 7 },
      ]}
      column1={
        <Box
          sx={{
            height: '100%',
            width: '100%',
            backgroundImage: `url(images/login-placeholder.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      }
      column2={
        !success ? (
          <ResetPasswordFormLayout
            onSubmit={onSubmit}
            passwordRules={passwordRules}
            {...formProps}
          />
        ) : (
          <ResetPasswordSuccessLayout
            buttonProps={{
              onClick: () => {
                navigate(ROUTE.signin);
                return '';
              },
            }}
          />
        )
      }
      sx={{
        root: { backgroundColor: 'common.white' },
        col2: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        },
      }}
    />
  );
};
