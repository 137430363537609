import { AlertColor, SnackbarOrigin } from '@mui/material';
import { useAtom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

export type ToastSettings = {
  open: boolean;
  message?: string;
  severity?: AlertColor;
  anchorOrigin?: SnackbarOrigin;
};

export const toastAtom = atomWithReset<ToastSettings>({
  open: false,
  message: '',
});

export const useToastAtom = () => {
  const [toast, setToast] = useAtom(toastAtom);
  return {
    toast,
    setToast: (t: ToastSettings) => setToast(t),
  };
};
