import { Box, Card, CardContent, Tooltip } from '@mui/material';
import { Product } from '../@types/lead';
import { Body2 } from './elements';

export interface AppliedProductItemProps {
  product: Product;
}

export const AppliedProductItem = ({ product }: AppliedProductItemProps) => (
  <Card
    variant="outlined"
    sx={{
      borderColor: 'secondary.main',
      minWidth: '150px',
      maxWidth: '300px',
      minHeight: '45px',
      maxHeight: '45px',
    }}
  >
    <CardContent sx={{ p: 0.5, '&:last-child': { paddingBottom: '8px' } }}>
      <Body2 fontWeight={600}>{product?.productType ?? 'MISSING TYPE'}</Body2>
      <Tooltip title={product?.productName ?? 'MISSING PRODUCT NAME'}>
        <Box>
          <Body2
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '300px',
            }}
          >
            {product?.productName ?? 'MISSING PRODUCT NAME'}
          </Body2>
        </Box>
      </Tooltip>
    </CardContent>
  </Card>
);
