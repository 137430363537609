import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';
import { ComponentProps } from 'react';
import { ButtonText } from './Typography';
import { mergeSx } from '../../utils';
import { AnchorLink } from './AnchorLink';

export type ButtonProps = Omit<MuiButtonProps, 'children'> & {
  onClick?: () => void;
  locale: ComponentProps<typeof ButtonText>['locale'];
};

export type ButtonLinkProps = ButtonProps & { path: string };

export const Button = ({ locale, ...props }: ButtonProps) => (
  <MuiButton variant="contained" {...props}>
    <ButtonText locale={locale} />
  </MuiButton>
);

export const ButtonLink = ({ locale, path, sx, ...props }: ButtonLinkProps) => (
  <AnchorLink button path={path} buttonProps={{ ...props }}>
    <ButtonText
      locale={locale}
      sx={mergeSx(
        {
          textDecoration: 'underline',
        },
        sx
      )}
    />
  </AnchorLink>
);
