import { GridSize, Breakpoint } from '@mui/material';
import { useMemo } from 'react';

export type GridSizes = Partial<Record<Breakpoint, GridSize>>;

type UseGridRatioColArray = (GridSize | GridSizes)[];
type UseGridRatioColObject = Partial<Record<Breakpoint, GridSize[]>>;
export type UseGridRatio = UseGridRatioColArray | UseGridRatioColObject;

/**
 * Utility hook to auto compute grid sizing based on an array input
 *
 * @param ratios Grid column ratios
 * @returns A list, matching the columns length, of grid sizes to spread over
 *  the Grid item component
 * @example
 * const [col1, col2] = useGridRatios([4, 8]);
 * // col1 = { xs: 4 }
 * // col2 = { xs: 8 }
 *
 * const [col1, col2] = useGridRatios([{ xs: 12, md: 4 }, 8]);
 * // col1 = { xs: 12, md: 4 }
 * // col2 = { xs: 8 }
 *
 * const [col1, col2] = useGridRatios([{ xs: 12, md: 4 }, { xs: 12, md: 8 }]);
 * // col1 = { xs: 12, md: 4 }
 * // col2 = { xs: 12, md: 8 }
 *
 * const [col1, col2] = useGridRatios({ xs: [12, 12], md: [4, 8] });
 * // col1 = { xs: 12, md: 4 }
 * // col2 = { xs: 12, md: 8 }
 */
export function useGridRatios(ratio: UseGridRatio) {
  return useMemo(() => {
    // 1st use case - (GridSize | ISizes)[]
    if (Array.isArray(ratio)) {
      return (ratio as UseGridRatioColArray).map(r =>
        !Number.isNaN(Number(r)) || r === 'auto' ? { xs: r as GridSize } : (r as GridSizes)
      );
    }
    // 2nd use case - Partial<Record<Breakpoint, GridSize[]>>
    const output: GridSizes[] = [];
    const ratios = typeof ratio === 'string' ? JSON.parse(ratio) : ratio;
    Object.entries<GridSize[]>(ratios).forEach(([breakpoint, ratioList]) => {
      ratioList.forEach((size, idx) => {
        // Clone the saved value
        const col = { ...(output[idx] || ({} as GridSizes)) };
        col[breakpoint as Breakpoint] = size;
        // Save the new value
        output[idx] = col;
      });
    });

    return output;
  }, [ratio]);
}

/**
 * Utility hook to auto compute an even grid sizing based on an array input
 *
 * @param items List of items. Will return a list of the same length or 1 if an empty/no list provided
 * @param options.size Size the evenly spaced out values are for. Default is `xs`
 * @param options.force Forces the same breakpoint size for all columns. CAUTION: If this specifies a
 *  value for the size then this value will take precedence.
 * @example
 * const cols = useEvenGridRatios([1, 2, 3]);
 * // cols = [{ xs: 4 }, { xs: 4 }, { xs: 4 }]
 *
 * const cols = useEvenGridRatios([1, 2, 3], { size: 'md' });
 * // cols = [{ md: 4 }, { md: 4 }, { md: 4 }]
 *
 * const cols = useEvenGridRatios([1, 2, 3], { force: { md: 3 }});
 * // cols = [{ xs: 4, md: 3 }, { xs: 4, md: 3 }, { xs: 4, md: 3 }]
 *
 * // CAUTION use case
 * const cols = useEvenGridRatios([1, 2, 3], { size: 'sm', force: { sm: 2 } } );
 * // cols = [{ sm: 2 }, { sm: 2 }, { sm: 2 }]
 */
export function useEvenGridRatios(
  items?: unknown[],
  options?: { size?: Breakpoint; force?: GridSizes }
) {
  const ratios = useMemo(() => {
    const { size = 'xs', force } = options || {};
    const itemCount = items?.length || 1;
    return (
      new Array(itemCount)
        // Array of GridSize e.g. [4, 4, 4]
        .fill(Math.floor(12 / itemCount))
        // Convert number values to GridSizes
        .map(col => ({ [size]: col, ...force }))
    );
  }, [items, options]);

  return useGridRatios(ratios);
}
