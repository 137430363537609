import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { mergeSx } from '../../utils';
import { WithSxKeys } from '../../constants';
import { AnchorLink, Body1, ButtonText } from '../elements';

export type NavItemProps = WithSxKeys<'root' | 'copy'> & {
  label: string;
  href: string;
  // eslint-disable-next-line react/no-unused-prop-types
  vstack?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: any) => void;
  button?: boolean;
  noReactRouter?: boolean;
};

export const NavItem = ({
  label,
  href,
  sx,
  classes,
  button,
  onClick,
  noReactRouter,
}: NavItemProps) =>
  button ? (
    <AnchorLink
      button
      path={href}
      onClick={onClick}
      sx={mergeSx({ textDecoration: 'none' }, sx?.root)}
      buttonProps={{
        variant: 'contained',
        color: 'secondary',
        sx: {
          py: 1.25,
          px: 2,
        },
      }}
      noReactRouter={noReactRouter}
    >
      <ButtonText
        locale={label}
        className={classes?.copy}
        sx={mergeSx(
          ({ typography }) => ({
            ...typography.button,
            color: 'common.black',
            textAlign: 'center',
            textTransform: 'uppercase',
            padding: 0,
            fontWeight: 700,
          }),
          sx?.copy
        )}
      />
    </AnchorLink>
  ) : (
    <Box
      sx={mergeSx(
        {
          mx: 3,
          '& .navlink': {
            textDecoration: 'none',
          },
          '& .active .MuiTypography-root': {
            borderBottom: ({ palette }) => `2px solid ${palette.secondary.main}`,
          },
        },
        sx?.root
      )}
    >
      {noReactRouter ? (
        <Body1
          locale={label}
          className={classes?.copy}
          sx={mergeSx(
            {
              color: 'common.black',
              textAlign: 'center',
              textTransform: 'uppercase',
              py: 1.25,
            },
            sx?.copy
          )}
        />
      ) : (
        <NavLink to={href} className="navlink" onClick={onClick}>
          <Body1
            locale={label}
            className={classes?.copy}
            sx={mergeSx(
              {
                color: 'common.black',
                textAlign: 'center',
                textTransform: 'uppercase',
                py: 1.25,
              },
              sx?.copy
            )}
          />
        </NavLink>
      )}
    </Box>
  );
