import Cookies from 'js-cookie';
import { atom, useAtom } from 'jotai';
import { SUBSCRIPTION } from '../constants';

//  this schema will need to be updated to closer match the user schema from the backend
export type User = Partial<{
  userId: string;
  fname: string;
  lname: string;
  email: string;
  companyName?: string;
  subscription?: SUBSCRIPTION;
  dateStyle: string;
  dateJoined?: Date;
  isVerified?: boolean;
  JSESSIONID?: string;
}>;

const defaultUser: User = {
  userId: '',
  fname: '',
  lname: '',
  email: '',
  subscription: SUBSCRIPTION.BASIC,
  dateStyle: 'MMMM dd yyyy',
  dateJoined: new Date('January 01 2019'),
  companyName: 'Acme Anvil Corp',
};

export const userAtom = atom<User>(defaultUser);

export const userSubscriptionAtom = atom(get => get(userAtom).subscription);

export const useUserAtom = () => {
  const [user, setUser] = useAtom(userAtom);

  return {
    user,
    setUser,
    updateUser: (u: Partial<User>) => setUser({ ...user, ...u }),
    login: (u: User) => {
      setUser(u);
    },
    logout: () => {
      Cookies.remove('site', {
        path: '/',
        domain: new URL(document.location.href).hostname,
      });
      setUser(defaultUser);
    },
  };
};

export const useUserSubscription = () => {
  const [level] = useAtom(userSubscriptionAtom);
  return {
    hasPermission: (p: SUBSCRIPTION[] | SUBSCRIPTION) =>
      (Array.isArray(p) ? p : [p]).includes((level as unknown) as SUBSCRIPTION),
  };
};
