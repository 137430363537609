import Cookies from 'js-cookie';
import { IUser } from '@renoworks/user-api';
import Axios from 'axios';
import { userApi } from './api';
import { rwproPasswordResetRedirectURL, SUBSCRIPTION } from '../constants';
import { User } from '../atoms';

export const submitVerificationCode = async (userId: string, code: string, site: string) =>
  userApi(site).submitVerificationCode(userId, code);

export const resendVerification = async (userId: string, site: string) =>
  userApi(site).sendVerification(userId);

export const loginUser = async (email: string, password: string, site: string) => {
  const response = await userApi(site).signIn(email, password);
  const { data } = response;

  return { ...response, data } as IUser;
};

export const updateUser = async (
  userId: string,
  updates: {
    email: string;
    password: string;
    canContact: boolean;
    userData: Record<string, unknown>;
    site: string;
  }
) => userApi(updates.site).updateUser(Number(userId), updates);

export const getPasswordResetKey = async (email: string, site: string) =>
  userApi(site).getPasswordResetKey(email, rwproPasswordResetRedirectURL, site);

export const forgotPassword = async (
  email: string,
  password: string,
  reset_key: string,
  site: string
) => userApi(site).resetPassword(email, password, reset_key, site);

export const resetPassword = async (
  email: string,
  password: string,
  resetKey: string,
  site: string
) => userApi(site).resetPassword(email, password, resetKey);

export const resetPasswordNonLoggedInUser = async (email: string, site: string) =>
  userApi(site).resetPasswordNonLoggedInUser(email, rwproPasswordResetRedirectURL, {}, site);

export const logout = async () => {
  await userApi(Cookies.get('site') ?? '').signOut();
};

export const getSession = async () => {
  const siteCookie = Cookies.get('site');

  if (siteCookie) {
    const session = await userApi(siteCookie ?? '').getSession();

    return session;
  }

  return null;
};

export const apiUserToAtomUser = (arg: IUser): User => {
  if (arg.site) {
    Cookies.set('site', arg.site, {
      path: '/',
      domain: new URL(document.location.href).hostname,
      expires: 60 * 60,
    });
  }

  return {
    userId: `${arg.user_id}`,
    fname: `${arg.data.firstName}`,
    lname: `${arg.data.lastName}`,
    email: `${arg.data.email}`,
    subscription: SUBSCRIPTION.BASIC, // TODO - Milestone 2
    dateStyle: 'yyyy/MM/dd',
    isVerified: arg.is_verified,
    JSESSIONID: arg.JSESSIONID,
    companyName: `${arg.data.companyName}`,
  };
};

export const getMeasurementDetails = async () => Axios.get('/measurements');
export const getSubDetails = async () => Axios.get('/subscription');
export const getSubCards = async () => Axios.get('/upgrade');
