import { memo, ComponentProps } from 'react';
import { mergeSx } from '../../utils';
import { Heading3 } from './Typography';

export type LeadScoreProps = Omit<ComponentProps<typeof Heading3>, 'locale' | 'noTranslate'> & {
  score: number | string;
};

export const LeadScore = memo(({ score, ...props }: LeadScoreProps) => (
  <Heading3
    {...props}
    locale={`${score}`}
    noTranslate={Number.isNaN(score)}
    fontWeight="600"
    sx={mergeSx({ color: 'secondary.main', textTransform: 'uppercase' }, props.sx)}
  />
));
