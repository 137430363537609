import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { Copy } from '../components';

// TODO: use renoworks 404 page?

export function NotFoundPage() {
  return (
    <Box>
      <Copy>That link has no power here</Copy>
      <Link to="/">
        <Copy>Go Back Home</Copy>
      </Link>
    </Box>
  );
}
