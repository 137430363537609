import { FC, FormEventHandler, useState } from 'react';
import { Stack } from '@mui/material';
import { Controller, useWatch } from 'react-hook-form';
import { Button, TitleDescription, Body1, Body2, Heading5 } from '../elements';
import { PasswordCheckerUiProps, PasswordCheckUi, PasswordInput, PlainInput } from '../widgets';
import { WithForm } from '../../constants';

export type ResetFormFields = Record<'password' | 'confirmPassword' | 'email' | 'site', string>;

export type ResetPasswordFormProps = WithForm<ResetFormFields> & {
  onSubmit: FormEventHandler<HTMLFormElement>;
  passwordRules: PasswordCheckerUiProps['rules'];
};

export const ResetPasswordFormLayout: FC<ResetPasswordFormProps> = ({
  onSubmit,
  control,
  formState: { errors, isValid },
  passwordRules,
}) => {
  const [showPasswordCheck, setShowPasswordCheck] = useState(false);
  const passwordValue = useWatch({
    control,
    name: 'password',
  });

  return (
    <form onSubmit={onSubmit} style={{ width: '60%', position: 'relative' }}>
      <Stack justifyContent="center" sx={{ mb: 5 }}>
        <TitleDescription title={<Heading5 locale="resetPasswordPage.heading" />} sx={{ mb: 2 }}>
          <Body1 locale="resetPasswordPage.body" />
        </TitleDescription>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <PasswordInput
              {...field}
              required
              label={<Body2 locale="resetPasswordPage.password" />}
              error={{ locale: errors?.password?.message }}
              onFocus={() => setShowPasswordCheck(true)}
              onBlur={() => setShowPasswordCheck(false)}
            />
          )}
        />
        <PasswordCheckUi
          show={showPasswordCheck}
          rules={passwordRules}
          value={passwordValue}
          sx={{ bottom: '-6%' }}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <PasswordInput
              {...field}
              required
              label={<Body2 locale="resetPasswordPage.confirmPassword" />}
              error={{ locale: errors?.confirmPassword?.message }}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <PlainInput
              {...field}
              required
              label={<Body1 locale="resetPasswordPage.email" />}
              error={{ locale: errors?.email?.message }}
            />
          )}
        />
      </Stack>
      <Button
        type="submit"
        locale="resetPasswordPage.heading"
        color="secondary"
        variant="contained"
        fullWidth
        disabled={!isValid}
        sx={{ p: 1.5 }}
      />
    </form>
  );
};
