import { UnpackNestedValue, UseFormHandleSubmit } from 'react-hook-form';
import { useLoadingAtom } from '../atoms';

export const useSubmit = <T extends object>(
  handleSubmit: UseFormHandleSubmit<T>
): ((
  fn: (d: UnpackNestedValue<T>) => void | Promise<void>
) => ReturnType<UseFormHandleSubmit<T>>) => {
  const { handleLoading } = useLoadingAtom();
  return (fn: (data: UnpackNestedValue<T>) => void | Promise<void>) =>
    handleSubmit(data => handleLoading(() => fn(data)));
};
