import { ChangeEvent, FC, useMemo, useState, MouseEvent } from 'react';
import { Box, AppBar, Toolbar, IconButton, Menu, MenuItem } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Copy } from '../elements/Copy';
import { NavItem, NavItemProps } from '../widgets';
import { ROUTE } from '../../constants';
import { useUserAtom } from '../../atoms';
import { logout } from '../../services';

export type PrimaryNavProps = {};

export const PrimaryNav: FC<PrimaryNavProps> = () => {
  const { user, logout: userReset } = useUserAtom();
  const navigate = useNavigate();
  const isLoggedIn = !!user.userId;

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navItems = useMemo<NavItemProps[]>(
    () =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [
        !isLoggedIn && { label: 'primaryNav.signin', href: ROUTE.signin },
        isLoggedIn && {
          href: '/sign-in',
          label: 'primaryNav.logout',
          onClick: (e: ChangeEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            logout().then(() => {
              userReset();
              navigate(ROUTE.signin);
            });
          },
        },
      ].filter(Boolean),
    [isLoggedIn, userReset, navigate] // will need isBasicUSer added back in once commented code is uncommented
  );

  return (
    <Box className="RW-PrimaryNav" sx={{ mb: 0.1 }}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'common.white',
        }}
      >
        <Toolbar sx={{ pt: 2, pb: 1 }}>
          {/* TODO replace with <Brand /> when Brand image is ready */}

          <Box sx={{ alignContent: 'baseline', flexDirection: 'row', width: '40%' }}>
            <Box
              component="img"
              src="/images/renoworks-color-logo.png"
              sx={{ width: '100%', maxWidth: '259px', mr: 2 }}
              display="inline-block"
            />
            <Copy
              variant="h4"
              locale="primaryNav.brand"
              sx={{
                color: 'secondary.main',
                flexGrow: 1,
                fontWeight: 300,
                verticalAlign: 'bottom',
                display: 'inline',
                whiteSpace: 'nowrap',
              }}
            />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          {/* Mobile Menu */}
          <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
            <IconButton
              aria-haspopup="true"
              aria-controls="primarynav-mobile-menu"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon color="primary" />
            </IconButton>
            <Menu
              id="primarynav-mobile-menu"
              open={!!anchorElNav}
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              onClose={handleCloseNavMenu}
              sx={{ py: 3 }}
            >
              {navItems.map(navItemProps => (
                <MenuItem key={navItemProps.label}>
                  <NavItem
                    {...navItemProps}
                    onClick={e => {
                      navItemProps.onClick?.(e);
                      handleCloseNavMenu();
                    }}
                  />
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* Desktop */}
          <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
            {navItems.map(navItemProps => (
              <NavItem key={navItemProps.label} {...navItemProps} />
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
