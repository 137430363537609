import * as yup from 'yup';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SplitPane, ForgotPasswordFormLayout, ForgotPasswordFormFields } from '../components';
import { createFormSchema, getContractorSiteParam } from '../utils';
import { useSubmit, useForm } from '../hooks';
import { ROUTE } from '../constants';
import { getPasswordResetKey } from '../services';
import { useToastAtom } from '../atoms';

const defaultValues: ForgotPasswordFormFields = {
  email: '',
  site: '',
};

export const formSchema = createFormSchema<ForgotPasswordFormFields>({
  email: yup
    .string()
    .email('formError.invalidEmail')
    .required('formError.required'),
  site: yup.string().required('formError.required'),
});

export const ForgotPasswordPage = () => {
  const formProps = useForm(defaultValues, formSchema);
  const createSubmit = useSubmit(formProps.handleSubmit);
  const { setToast } = useToastAtom();
  const navigate = useNavigate();
  const onSubmit = createSubmit(async data => {
    try {
      await getPasswordResetKey(data.email, getContractorSiteParam(data.site));
      setToast({
        open: true,
        message: 'forgotPasswordPage.recoveryLink',
      });
      return navigate(ROUTE.signin);
    } catch (error) {
      return setToast({
        open: true,
        message: 'forgotPasswordPage.recoveryLinkFail',
        severity: 'error',
      });
    }
  });

  return (
    <Box sx={{ height: '100%' }}>
      <SplitPane
        columnGridRatios={[
          { xs: 12, lg: 5 },
          { xs: 12, lg: 7 },
        ]}
        column1={
          <Box
            sx={{
              height: '100%',
              backgroundImage: `url(/images/login-placeholder.png)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        }
        column2={
          <ForgotPasswordFormLayout {...formProps} onSubmit={onSubmit} route={ROUTE.signin} />
        }
        sx={{
          root: { backgroundColor: 'common.white' },
          col2: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
        }}
      />
    </Box>
  );
};
