export type Locale = { code: string; translations: object };

export const EN: Locale = {
  code: 'en',
  translations: {
    primaryNav: {
      brand: 'Lead Portal',
      leads: 'Jobs & Leads',
      account: 'Account',
      logout: 'Log Out',
      upgrade: 'Upgrade To Pro',
      createAccount: 'Create a Free Account',
      signin: 'Welcome back - Sign In!',
    },
    footer: {
      privacyPolicy: 'Privacy Policy',
      disclaimer: 'Disclaimer',
      tos: 'Terms Of Service',
    },
    upgradePanel: {
      heading: 'Pro coming soon',
      firstSentence:
        'Upgrade to unlock the full details of your leads.You will also gain access to valuable content and tools including:',
      listItem1: 'Customer lead scores and profiles',
      listItem2: 'Detailed project information and design selections',
      listItem3: 'Property information and imagery',
      listItem4: "Industry's best design and measurement solutions",
      button: "Notify me when it's out",
    },
    profilePage: {
      fname: 'First name',
      lName: 'Last name',
      email: 'Email',
      cName: 'Company Name',
      buttons: {
        update: 'Update Information',
        resetPass: 'Reset Password',
      },
      mainHeading: 'Subscription Information',
      date: 'Date Started',
      subType: 'Subscription Type',
      paymentPlan: 'Payment Plan',
      subscriptionType: {
        basic: 'Basic Subscription',
        pro: 'Pro Subscription',
      },
      subscriptionPayments: {
        monthly: 'Monthly',
        free: 'Free',
      },
      toastMessages: {
        success: 'Your account has been updated',
        error: 'Your account could not be updated',
        pleaseLogin: 'Please login',
      },
    },
    loginPage: {
      signIn: 'Sign in',
      caption: "Don't have an account?",
      createAccountLink: 'Create account',
      heading: 'Login to account',
      email: 'Email',
      site: 'Site URL',
      password: 'Password',
      forgotPasswordLink: 'Forgot password',
      errors: {
        invalidEmail: 'Invalid email format',
        requiredEmail: 'Email is required!',
        requiredSite: 'Site is required!',
        requiredPassword: 'Password is required!',
        passwordLength: 'Password must be more than 6 characters',
      },
      helpers: {
        site: 'Add your renoworks site address here.',
      },
    },
    forgotPasswordPage: {
      heading: 'Forgot Password?',
      buttonText: 'Send reset instructions',
      recoveryLink: 'Recovery Link Has Been Sent Successfully!',
      recoveryLinkFail: ' There was an error please try again',
      email: 'Email',
      site: 'Site',
      body:
        'Enter the email address associated with your account. We will send instructions on how to reset your password.',
    },
    resetPasswordPage: {
      heading: 'Reset password',
      body: 'Enter your new password',
      password: 'New Password',
      confirmPassword: 'Confirm New Password',
      successHeading: 'Success!',
      successBody: 'Your password has been reset.',
      successLink: 'Sign In Now',
      email: 'Confirm Email Address',
      site: 'Site',
      errors: {
        resetPasswordError: 'Your password is not valid',
      },
    },
    createAccountPage: {
      heading: 'Create your free account',
      caption: 'Already have an account?',
      captionLink: 'Sign in now',
      buttonText: 'Create Account',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm password',
      company: 'Company name',
      termsAndConditions:
        "I agree to the Renoworks <a href=' https://www.renoworks.com/tos' >Terms & Conditions</a> and <a href='https://www.renoworks.com/privacy-policy'>Privacy Policy </a>.",
      newsAndPromo:
        'We may send you emails with news or promotions occasionally. Go to your email notification page to control the messages we send.',
      errors: {
        fullName: 'Please enter your full name',
        createAccountError: 'Error please try again later',
      },
    },
    homePage: {
      headLine: 'Looking For New Customers?',
      subHeadLine:
        "We'll connect you with project ready homeowners looking to hire pros in your area",
      body:
        'Customers with complete project profiles are waiting to chat with professionals like you. Connect, scope and close customers faster and easier. Sign up is free!',

      buttons: {
        signIn: 'sign in to account',
        createAccount: 'create account',
      },
    },
    sideBar: {
      title: 'My account',
      sideBarItems: {
        first: 'My profile',
        second: 'Billing & receipts',
        third: 'My subscriptions',
      },
    },
    toast: {
      projectAttachedSuccess: 'Success - project has been added!',
    },
    formError: {
      invalidEmail: 'Invalid email format',
      required: 'Field is required',
      passwordLength: 'Password must be more than 6 characters',
      passwordsMustMatch: 'Passwords must match',
      passwordMaxLength: 'Password must be less then 64 Characters',
      passwordLower: 'Password must have at least one lowercase character',
      passwordUpper: 'Password must have at least one uppercase character ',
      passwordSpecial: 'Password must have one special character',
      passwordNumber: 'Password must have one number',
    },
    verify: {
      title: 'Verify email',
      subHeading:
        'We’ve sent you a verification email to the email you’ve provided,please click the link to verify your account',
      input: {
        label: 'Verification code',
      },
      buttons: {
        buttonLink: 'send code again',
        buttonContained: 'confirm account',
      },
      toast: {
        emailSuccess: 'Verification email has been resent',
        emailFail: 'There has been an error please try again later',
        codeSuccess: 'Account has been verified',
        codeFail: 'Account could not be verified please try again',
      },
    },
    projectsPage: {
      leadsLayout: {
        heading: 'Leads',
        dropdownLabel: 'Sort By: High To Low',
        noLeadsMessage: "You currently don't have any leads",
      },
      selectionOptions: {
        high: 'High',
        low: 'Low',
      },
      projectsPageHeader: {
        buttons: {
          link: 'open design project',
        },
      },
      jobTracking: {
        heading: 'Job Tracking',
        infoHeader: 'Job Information',
        contactHomeowner: 'Contact Homeowner',
        defineDates: 'Define dates for work to start and end',
        closeSale: 'Close Sale',
        finalizedDesign: 'Select a final design with homeowner',
        takeOffList: 'Create takeoff list',
        finalProducts: 'Select final products',
        purchaseProducts: 'Purchase all projects',
        startJob: 'Start Job',
        jobComplete: 'Job Completed',
        scope: 'Scope',
        budget: 'Budget',
        timeline: 'Timeline for job',
        updateInfoBtn: 'Update Job Information',
      },
    },
    myLeadsPage: {
      header: {
        title: 'my jobs & leads',
      },
    },
    detailsSection: {
      subHeadline: 'lead qualification details',
    },
    secondaryNavItems: {
      leadProfile: 'Lead Profile',
      projectInfo: 'Project Information',
      jobInfoTracking: 'Job Info & Tracking',
      measure: 'Measurements',
      final: 'Final Documentation',
    },
    trackingPage: {
      jobInformation: 'Job Information',
      scope: 'Scope',
      budget: 'Budget',
      timeLine: 'Timeline for job',
      jobTracking: 'Job Tracking',
      to: 'To',
      checkboxItems: {
        contactHomeowner: 'Contact Homeowner',
        defineDate: 'Define dates for work to start and end',
        closeSale: 'Close Sale',
        selectFinal: 'Select final design with homeowner',
        createTakeOffList: 'Create takeoff list',
        select: 'Select final products',
        purchase: 'Purchase all products',
        start: 'Start Job',
        switchLabel: 'Job Completed',
      },
      button: 'Update Job Information',
    },
    passwordRules: {
      length: 'Between 6 and 64 characters',
      upperAndLower: 'Contains one upper and lowercase letter',
      specialChar: 'Contains one number and special character',
      strengthBar: {
        vWeakmess: 'Very weak password',
        weakMess: 'Weak password',
        modMess: 'Moderate password',
        goodMess: 'Good password',
      },
    },
    projectLeadProfile: {
      noLeads: "You don't currently have any leads",
    },
    apiErrorCode: {
      codeTitle: 'Code',
    },
    leadTrackingLayout: {
      toast: {
        fail: 'The lead has been updated',
        success: 'There was an error please try again later ',
      },
    },
    basicSubscription: {
      heading: 'Pro coming soon!',
      firstSentence:
        'Upgrade to unlock the full qualified lead profile. You will gain valuable content and tools, including:',
      listItem1: 'Lead Score and lead details',
      listItem2: 'Project Information and design details',
      listItem3: 'Details property Information',
      listItem4: 'Job Information',
    },
    paidSubscription: {
      billingInfo: 'Billing Information',
      monthly: 'Monthly - $0000.00',
      creditCard: 'credit card',
      zipCode: 'Billing Zipcode',
      nextBilling: 'Next billing:',
      billingHistory: 'Billing History',
      cancelSubscription: 'Cancel Subscription',
      table: {
        month: 'Month',
        license: 'License Type',
        amount: 'Amount',
      },
    },
    paypalForm: {
      upgrade: 'Upgrade To Pro!',
      billingInfo: 'Billing Information',
      continue: 'Select "Continue" to log-in',
      notifications:
        'We may send you emails with news or promotions occasionally. Go to your Email notifications page to control the messages we send.',
      checkbox: 'I agree to the FastTrack terms and conditions and Privacy Policy.',
      button: 'Continue to Paypal',
    },
    projectInformation: {
      designEngagement: 'Design Engagement',
      products: 'Most Applied Products',
      productsDesign: 'Products in this design:',
    },
    finalLook: {
      finalProductsApplied: 'Final Products Applied',
      productName: 'Product Name',
      productDetails: 'Product Details',
      addAnotherProduct: 'Add Another Product',
      update: 'Update Job Information',
      feedback: 'Home Owner Feedback',
      comments: 'Write comments here',
      product: 'product 1',
      notes: 'Write notes here',
      drag: 'Drag and drop photos of the complete job here.',
      link: 'Or, click to browse your device',
      title: 'Final Look',
    },
    measurements: {
      sidebarTitle: 'Perimeter, Ridges and Valleys',
      exterior: 'Exterior',
      roof: 'Roof',
    },
    creditCardForm: {
      preference: 'Payment Preference',
      encryption: 'Transactions are encrypted and secure',
      number: 'Credit Card Number',
      expiry: 'Expiry Date',
      cvv: 'Security Code',
      zip: 'Billing Zipcode',
      button: 'Upgrade to Pro',
    },
    upgradePage: {
      button: {
        disabled: 'This is your current subscription',
      },
    },
    lead: {
      projectInfo: {
        photoOfHouse: 'Project Design Before',
        photoOfLastDesign: 'Project Design After',
        productApplied: 'Applied Products',
      },
      noLead: 'Lead Not Found, Please use lead link provided',
      projectLink: 'OPEN DESIGN PROJECT',
    },
  },
};
