import { FC } from 'react';
import { Box, Stack, Grid } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { TitleDescriptionProps, ButtonProps, Button } from '../elements';
import { WithSx } from '../../constants';
import { mergeSx } from '../../utils';

export type ContentCardProps = WithSx & {
  cardTitle: TitleDescriptionProps;
  button1: ButtonProps;
  button2?: ButtonProps;
};

export const ContentCard: FC<ContentCardProps> = ({
  sx,
  cardTitle,
  children,
  button1,
  button2,
}) => (
  <Stack
    spacing={3}
    sx={mergeSx(
      {
        p: 2,
        // white bg default with slight transparency
        backgroundColor: ({ palette }) => alpha(palette.common.white, 0.6),
      },
      sx
    )}
  >
    {cardTitle}
    <Box sx={{ py: '10px' }}>{children}</Box>
    <Grid sx={{ margin: 'auto', textAlign: 'center' }} container alignItems="center">
      <Grid item xs={12} sm={12}>
        <Button {...button1} />
      </Grid>
      {/* button2 is optional */}
      {button2 && (
        <Grid item xs={12} sm={6}>
          <Button {...button2} />
        </Grid>
      )}
    </Grid>
  </Stack>
);
