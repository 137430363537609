import { FC, ReactNode } from 'react';
import { Grid, GridProps } from '@mui/material';
import { useGridRatios, UseGridRatio } from '../../hooks/useGridRatios';
import { mergeSx } from '../../utils';
import { WithSxKeys } from '../../constants';

export type SplitPaneProps = WithSxKeys<'root' | 'col1' | 'col2'> &
  GridProps & {
    columnGridRatios?: UseGridRatio;
    column1?: ReactNode;
    column2?: ReactNode;
  };

export const SplitPane: FC<SplitPaneProps> = ({
  spacing,
  column1,
  column2,
  columnGridRatios = [
    { xs: 12, md: 6 },
    { xs: 12, md: 6 },
  ],
  sx,
  ...props
}) => {
  const [col1Sizes, col2Sizes] = useGridRatios(columnGridRatios);

  return (
    <Grid
      container
      {...props}
      sx={mergeSx(
        {
          display: 'flex',
          width: '100%',
          height: '100%',
        },
        sx?.root
      )}
    >
      <Grid item sx={sx?.col1} {...col1Sizes}>
        {column1}
      </Grid>
      <Grid item sx={sx?.col2} {...col2Sizes}>
        {column2}
      </Grid>
    </Grid>
  );
};
