import { Box } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useAtom } from 'jotai';
import { useParams } from 'react-router';
import { PageHeaderLayout } from '../components/layout';
import { AnchorLink, ButtonText, Heading4, LeadScore } from '../components';
import { LeadLayout } from '../components/layout/LeadLayout';
import { PageFooterLayout } from '../components/layout/PageFooter';
import { fetchLeadData } from '../services/api';
import { leadAtom } from '../atoms/leadAtom';

export const LeadPage = () => {
  const { leadId } = useParams();
  const [lead, setLead] = useAtom(leadAtom);

  const projectPath = useMemo(() => {
    const link = lead?.projects[0].links?.find(l => l.linkType === 'Project URL');

    return link ? link.link : ''; // TODO: custom 404 page
  }, [lead.projects]);

  // todo: move this to be part of login flow via a custom hook?
  useEffect(() => {
    if (leadId) {
      fetchLeadData(leadId, setLead);
    }
  }, [setLead, leadId]);

  return (
    <Box sx={{ background: 'common.white', height: '85%' }}>
      <PageHeaderLayout
        col1Component={
          <>
            <LeadScore score={lead?.leadDetail?.aboutLead?.leadLabel || 'TBD'} />
            <Heading4
              locale={lead?.leadDetail?.contactInfo?.leadName || '-'}
              fontWeight="600"
              sx={{ color: 'common.white', textTransform: 'capitalize', whiteSpace: 'nowrap' }}
            />
          </>
        }
        col2Component={
          <AnchorLink
            button
            path={projectPath}
            sx={{
              color: 'primary.main',
              backgroundColor: 'secondary.main',
              justifySelf: 'end',
              '&:hover': { backgroundColor: 'secondary.dark' },
              minWidth: '200px',
            }}
          >
            <ButtonText locale="lead.projectLink" />
          </AnchorLink>
        }
      />
      <LeadLayout />
      <PageFooterLayout />
    </Box>
  );
};
