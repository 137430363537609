import { Box } from '@mui/material';
import { Body1 } from './Typography';

export type StrengthBarProps = {
  strength: number;
};

const colors = ['red', 'orange', 'blue', 'green'];
const messages = [
  'passwordRules.strengthBar.vWeakmess',
  'passwordRules.strengthBar.weakMess',
  'passwordRules.strengthBar.modMess',
  'passwordRules.strengthBar.goodMess',
];

export const StrengthBar = ({ strength }: StrengthBarProps) => (
  <>
    <Box sx={{ width: '100%', height: '15px', position: 'relative' }}>
      <Box
        sx={{
          height: '12px',
          width: `${(strength / 4) * 100}%`,
          backgroundColor: `${colors[strength - 1]}`,
          position: 'absolute',
          transition: 'width .5s',
        }}
      />
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
        <Body1 locale={messages[strength - 1]} />
      </Box>
    </Box>
  </>
);
