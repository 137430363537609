import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { replace } from './components';

export type RichTextProps = {
  html: string;
};

type ChildrenType = JSX.Element | null;

/**
 * Converts a DOM string into valid React JSX markup using components.
 * All attributes provided on each element will be passed to the rendered component,
 * this means react props can be passed in this way.
 *
 * __NOTE:__ Since the input is a string, passing functions as props won't work and objects
 * will need to be properly accounted for by the rendered component.
 */
export function RichText({ html }: RichTextProps) {
  const [children, setChildren] = useState<ChildrenType>(null);

  useEffect(() => {
    setChildren(parse(DOMPurify.sanitize(html), { replace }) as ChildrenType);
  }, [html]);

  return children;
}
