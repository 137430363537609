import { Grid } from '@mui/material';
import { useAtom } from 'jotai';
import { LeadDetailPane } from './LeadDetailPane';
import { leadAtom } from '../../atoms/leadAtom';
import { LeadImagePane } from './LeadImagePane';

export const LeadLayout = () => {
  const [lead] = useAtom(leadAtom);
  const leadSections = lead.getDetailAsList();

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <LeadDetailPane leadSections={leadSections} />
      </Grid>
      <Grid item xs={12} md={6} height="100%">
        <LeadImagePane />
      </Grid>
    </Grid>
  );
};
