import { Collapse, Stack } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SplitPane } from './SplitPane';

export type PageHeaderProps = {
  col1Component: ReactNode;
  col2Component: ReactNode;
};

export const PageHeaderLayout = ({ col1Component, col2Component }: PageHeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    setIsOpen(false);
  }, [isDeskTop]);

  return (
    <SplitPane
      spacing={2}
      columnGridRatios={[
        { xs: 12, sm: 6, md: 4 },
        { xs: 12, sm: 6, md: 8 },
      ]}
      sx={{
        root: {
          p: 2,
          mt: { xs: 9, sm: 12, md: 12, lg: 9 }, // TODO: calc nav height for responsive
          height: 'auto',
          alignItems: { xs: 'inherit', sm: 'center' },
          justifyContent: 'space-between',
          backgroundColor: 'primary.main',
          minWidth: '390px',
        },
        col2: { display: 'flex', justifyContent: 'end' },
      }}
      column1={
        <>
          <Stack direction="row" spacing={2} alignItems="center">
            {col1Component}
          </Stack>

          <Stack alignItems="start" sx={{ mt: 1, display: { xs: 'inherit', md: 'none' } }}>
            <Collapse in={isOpen}>
              <Stack alignItems="start" sx={{ color: 'common.white' }}>
                {col2Component}
              </Stack>
            </Collapse>
          </Stack>
        </>
      }
      column2={col2Component}
    />
  );
};
