import { useMemo } from 'react';
import { SvgIconProps } from '@mui/material';
import {
  Help,
  AccountCircle,
  Visibility,
  VisibilityOff,
  ThumbUp,
  CalendarToday,
  AccountBox,
  Print,
  Search,
  Edit,
  Person,
  Email,
  ArrowBack,
  Check,
  Menu,
  ChevronLeft,
  ChevronRight,
  ExpandMore,
  ExpandLess,
  Lock,
  Close,
  CloudUpload,
  DeleteOutline,
  AddCircleOutline,
  Launch,
  CropSquare,
  Circle,
} from '@mui/icons-material';
import { WithSx } from '../../constants';

// Use as a wrapper for custom SVG icons when files are sourced
// function CustomIcon(props: SvgIconProps) {
//   return (
//     <SvgIcon {...props}>
//       <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
//     </SvgIcon>
//   );
// }

export type IconType =
  | 'info'
  | 'eyeopen'
  | 'eyeclosed'
  | 'thumbsup'
  | 'calendar'
  | 'profile'
  | 'subscription'
  | 'print'
  | 'search'
  | 'edit'
  | 'user'
  | 'email'
  | 'back'
  | 'checkmark'
  | 'hamburger'
  | 'caretup'
  | 'caretdown'
  | 'caretleft'
  | 'caretright'
  | 'lock'
  | 'close'
  | 'cloudUpload'
  | 'accountCircle'
  | 'addCircleOutline'
  | 'deleteOutline'
  | 'launch'
  | 'accountCircle'
  | 'cropSquare'
  | 'circle';

export type IconProps = SvgIconProps &
  WithSx & {
    type: IconType;
  };

const iconMap: Record<IconType, typeof Help> = {
  accountCircle: AccountCircle,
  info: Help,
  eyeopen: Visibility,
  eyeclosed: VisibilityOff,
  thumbsup: ThumbUp,
  calendar: CalendarToday,
  profile: AccountBox,
  subscription: AccountBox,
  print: Print,
  search: Search,
  edit: Edit,
  user: Person,
  email: Email,
  back: ArrowBack,
  checkmark: Check,
  hamburger: Menu,
  caretdown: ExpandMore,
  caretup: ExpandLess,
  caretleft: ChevronLeft,
  caretright: ChevronRight,
  lock: Lock,
  close: Close,
  cloudUpload: CloudUpload,
  deleteOutline: DeleteOutline,
  addCircleOutline: AddCircleOutline,
  launch: Launch,
  cropSquare: CropSquare,
  circle: Circle,
};

export const Icon = ({ type, ...props }: IconProps) => {
  const Component = useMemo(() => iconMap[type], [type]);
  return <Component {...props} />;
};
