import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { Body2 } from './Typography';
import { LeadDetailItem } from '../../atoms/leadAtom';

export interface LeadDetail {
  label: string;
  value: string;
}

export interface LeadDetailProps {
  leadDetailItem: LeadDetailItem;
}

const LeadItemContainer = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
}));

export const DetailItem = ({ leadDetailItem }: LeadDetailProps) => {
  const { label, value } = leadDetailItem;
  return (
    <LeadItemContainer container item xs={12} direction="row" mb={1} minWidth="290px">
      <Grid item xs={6}>
        <Body2>{label}</Body2>
      </Grid>
      <Grid item xs={6} whiteSpace="nowrap" minWidth="100px">
        <Body2 alignItems="end" sx={{ display: 'flex', justifyContent: 'end' }} fontWeight={600}>
          {value}
        </Body2>
      </Grid>
    </LeadItemContainer>
  );
};
