import { useAtom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

export type Measurements = [
  {
    id: string;
    title: string;
    desc: string;
    color: string;
  }
];

export const measurementsAtom = atomWithReset<Measurements>([
  {
    id: '',
    title: '',
    desc: '',
    color: '',
  },
]);

export const useMeasurementsAtom = () => {
  const [data, setData] = useAtom(measurementsAtom);
  return {
    data,
    setData: (d: Measurements) => setData(d),
  };
};
